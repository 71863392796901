import React from "react"
import { graphql } from "gatsby"
import Header from "../templates/header-template.jsx"
import Hero from "../templates/hero-template.jsx"
import Footer from "../templates/footer-template.jsx"

export const data = graphql`
  {
    contentfulHomepage(contentful_id: { eq: "4pWvw13ShXUXWKyDj0spuF" }) {
      highlight {
        highlight
      }
      headerImage {
        title
        file {
          url
        }
      }
      highlightDescription {
        highlightDescription
      }
    }
  }
`

const Homepage = ({ data }) => {
  const { highlight } = data.contentfulHomepage.highlight
  const { highlightDescription } = data.contentfulHomepage.highlightDescription
  const headerImage = data.contentfulHomepage.headerImage[0]

  return (
    <div>
      <Header />
      <Hero
        image={headerImage.file.url}
        highlight={highlight}
        description={highlightDescription}
      />
      <Footer />
    </div>
  )
}

export default Homepage
